import axios from "axios";

const API_URL = 
   // If the API is Fetched  from Replit
   // "https://6299eac2-0db7-4082-8d16-2ca77bdf682f-00-l1sxgeoo9pk5.sisko.replit.dev/api/analyze/";

   // If the API is Fetched  from Localhost
    // "http://127.0.0.1:8000/api/analyze/";

   // Hosted on Render
   // https://sitefend.onrender.com/api/analyze/";

  //Hosted on PythonAnywhere
   "https://sitefend.pythonanywhere.com/api/analyze/";

  // //Latest API - 9/17/24 Monday
  // 'https://78f5da10-1f76-4f72-85b2-dc179c779cf5-00-2s89kmfylhhj.pike.replit.dev/api/analyze/'
  
export const analyzeCode = async (code) => {
  try {
    const response = await axios.post(
      API_URL,
      { code },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    console.log("API call successful:", response.data);
    // Ensure that response.data.result is the correct path to your data
    return response.data.result;
  } catch (error) {
    console.error("There was an error analyzing the code!", error);
    return null;
  }
};
