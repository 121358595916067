import { useState, useRef, useEffect} from "react";
import "react-multi-carousel/lib/styles.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import TrackVisibility from "react-on-screen";
import { analyzeCode } from "../api";
import { Reports } from "./Reports";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import {vscodeLight} from '@uiw/codemirror-theme-vscode';
import { myTheme , extensions} from "../tools/CodeMirrorTheme";
import Doc from "../assets/img/doc_word_count.svg" 



export const Analyze = () => {
  const resultsRef = useRef(null); // Create a ref for the results section
  const [activeLink, setActiveLink] = useState('home');
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [securityScore, setSecurityScore] = useState(0);
  const [vulnerabilities, setVulnerabilities] = useState("");
  const [suggestedFixes, setSuggestedFixes] = useState("");
  const [bestPractices, setBestPractices] = useState("");
  const [secureCode, setSecureCode] = useState("");
  const [progress, setProgress] = useState(0);
  var codeWordCount = 0;
  const [charCount, setCharacterCount] = useState(0);
  
  //Word Counter
  useEffect(() => {
    const characters = code.replace(/\s+/g, '').length;
    setCharacterCount(characters);
  }, [code])

  const handleSubmit = async () => {
    setLoading(true);
    setProgress(0);
    setShowResults(false);

    // Simulate progress bar
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 90) {
          clearInterval(progressInterval);
          return prev;
        }
        return prev + Math.random() * 10;
      });
    }, 500);

    try {
      const result = await analyzeCode(code);

      setLoading(false);
      setProgress(100);

      if (result) {
        setSecurityScore(
          parseInt(result.overall_score.replace("**", ""), 10) || 0,
        );
        setVulnerabilities(
          result.detailed_report.vulnerabilities || "No vulnerabilities found.",
        );
        setSuggestedFixes(
          result.detailed_report.suggested_fixes || "No suggested fixes.",
        );
        setBestPractices(
          result.detailed_report.best_practices || "No best practices.",
        );
        setSecureCode(
          result.secure_code || "Your Code is Secure",
        );

        setShowResults(true);
        setActiveLink('analyze');
         // Scroll to the results section
         resultsRef.current.scrollIntoView({ behavior: 'smooth' });

      } else {
        setVulnerabilities("Failed to analyze code");
        setSuggestedFixes("");
        setBestPractices("");
        setSecurityScore(0);
        setShowResults(false);
      }
    } catch (error) {
      console.error("Error analyzing code:", error);
      setVulnerabilities("Error analyzing code");
      setSuggestedFixes("");
      setBestPractices("");
      setSecurityScore(0);
      setLoading(false);
      setShowResults(false);
    }
  };

  return (
    <section className="analyze" id="analyze">
  <div className="container d-flex justify-content-center align-items-center">
    <div className="analyze-bx wow zoomIn">
      <div className="sub-sec">
        <TrackVisibility>
          {({ isVisible }) => (
            <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
              <div className="txt-bx">
                <div className="top-bx">
                  <div className="word-count">
                  <img src={Doc} alt="Document Svg" className="input-svgs" />
                  {charCount}/5000
                  </div>
                </div>
                  <CodeMirror 
                        theme={myTheme}
                        highlightActiveLine = {false}
                        className="code-input-bx"
                        value={code}
                        onChange={(val) => {
                          console.log('val:', val);
                          setCode(val);
                        }}
                        extensions={extensions}
                  />
                <div className="btm-line">
                For the best results, your prompt shouldn't exceed 5,000 characters.
                </div>
                <div className="loading-section">
                {loading && (
                  <div className="progress-bar-container prog-bar-cont">
                    <ProgressBar
                      now={progress}
                      label={`${Math.round(progress)}%`}
                      className="prog-bar"
                      variant="prog"
                    />
                  </div>
                )}
                <button onClick={handleSubmit} className="check-btn">
                  Check Security
                </button>
                </div>
              </div>
            </div>
          )}
        </TrackVisibility>
      </div>
    </div>
  </div>

  <div className="result" ref={resultsRef}>
    {showResults && (
      <Reports
        securityScore={securityScore}
        vulnerabilities={vulnerabilities}
        suggestedFixes={suggestedFixes}
        bestPractices={bestPractices}
        secureCode={secureCode}
      />
    )}
  </div>
</section>

  );
};
